import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse, GetItem } from "@/api/user-export/response";
import { GetRequest } from "@/api/user-export/request";
import * as UserExportAPI from "@/api/user-export";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "user-export/get";

/**
 * ユーザー情報ダウンロード情報取得API（/get-user-export）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  searchResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getExcelItems() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.excel;
    } else {
      return [] as GetItem[];
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      return this.searchResponse.results;
    } else {
      return [] as GetItem[];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const searchResponse = await UserExportAPI.get(getRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
