import { Vue, Component, Prop } from 'vue-property-decorator';
import { ExportRequest } from "@/api/user/request";
import * as UserAPI from "@/api/user";
import { saveAs } from "file-saver";
import Flash from "@/store/common/flash";

enum Format {
  CSV
}

@Component
export default class MyComponent extends Vue {
  dlPathData: Record<string, string> = {};
  titleListForCustomerDl: Record<string, string> = {
    'apli': 'アプリ利用状況',
    'profile': 'プロフィール情報',
    'stamp': 'スタンプカード情報',
    'point-reduce': 'ポイントカード(減算方式)',
    'point-comp': 'ポイントカード(コンプリート方式)',
    'talk': 'トーク利用状況',
    'next': '次回予約情報',
    'coupon': 'クーポン利用状況',
    'past-data': '過去集計データ(~2023/07/11)'
  };

  @Prop({ type: Number, default: 0 })
  shopId!: number;

  @Prop({ type: Array, default: () => [] })
  fileList!: any[];

  @Prop({ type: String, default: '' })
  fileType!: string;

  /**
   * ラジオボタンで選択した帳票をダウンロードするメソッド
   * @param shopId number 店舗ID
   * @param dlPathData string ダウンロードファイル名
   * @returns 
   */
  async download(shopId: number, dlPathData: any) {
    if (!dlPathData) {
      return;
    }
    const exportResponse = await UserAPI.exportOne(this.createExportRequest(shopId, dlPathData));
    if (exportResponse && exportResponse.statusCd !== 200) {
      await Flash.setErrorNow({
        message: exportResponse.message,
        showReloadButton: false
      });
      return;
    }

    const blob = new Blob([exportResponse.results.zipString], {
      type: "application/zip"
    });
    saveAs(blob, exportResponse.results.fileName);
  }
  /**
   * ユーザー情報ダウンロードAPIをコールするメソッド
   * @param shopId number 店舗ID
   * @param dlPathData string ダウンロードファイル名
   * @returns 
   */
  private createExportRequest(shopId: number, dlPathData: string): ExportRequest {
    const request: ExportRequest = {} as ExportRequest;
    if (dlPathData) {
      request.id = shopId;
      request.dlPath = dlPathData;
      request.fileType = Format.CSV;
    }
    return request;
  }
}