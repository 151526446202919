import { render, staticRenderFns } from "./downloadFileInfo.vue?vue&type=template&id=609e2e90&scoped=true&"
import script from "../../models/customers/downloadFileInfo.ts?vue&type=script&lang=ts&"
export * from "../../models/customers/downloadFileInfo.ts?vue&type=script&lang=ts&"
import style0 from "./downloadFileInfo.vue?vue&type=style&index=0&id=609e2e90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609e2e90",
  null
  
)

export default component.exports