import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/create-excel/response";
import { GetRequest, RegistRequest } from "@/api/create-excel/request";
import * as CreateExccelAPI from "@/api/create-excel";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "create-excel/get";

/**
 * ユーザー情報ダウンロード情報取得API（/get-user-export）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getStatus() {
    if (this.getResponse.results) {
      return this.getResponse.results.status;
    } else {
      return 1;
    }
  }

  get getShopName() {
    if (this.getResponse.results) {
      return this.getResponse.results.name;
    } else {
      return null;
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const getResponse = await CreateExccelAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async regist(registRequest: RegistRequest) {
    const getResponse = await CreateExccelAPI.regist(registRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
