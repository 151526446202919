import service from "@/api/service";
import { GetRequest, RegistRequest } from "@/api/create-excel/request";
import { GetResponse } from "@/api/create-excel/response";

/**
 * お客様エクセル生成状態取得APIをコールします。
 *
 * @param getRequest お客様エクセル生成状態取得APIのリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-create-excel", getRequest);
  return response.data as GetResponse;
}

/**
 * お客様エクセル生成処理登録APIをコールします。
 *
 * @param getRequest お客様エクセル生成処理登録APIのリクエストボディ
 * @return GetResponse
 */
export async function regist(registRequest: RegistRequest) {
  const response = await service.post("/regist-create-excel", registRequest);
  return response.data as GetResponse;
}
