import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "../../components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { TableOptions } from "@/api/request";
import { GetRequest } from "@/api/user-export/request";
import { CreateExcelRequest } from "@/api/create-excel/request";
import { GetItem } from "@/api/user-export/response";
import UserExportGet from "@/store/user-export/get";
import { GetStatus } from "@/api/create-excel/response";
import CreateExcel from "@/store/create-excel/get";
import RegistCreateExcel from "@/store/create-excel/register";
import UserShopGet from "@/store/user-shop/get";
import Flash from "@/store/common/flash";
import downloadFileInfo from "../../views/customers/downloadFileInfo.vue";

enum Format {
  Excel,
  CSV
}
const STATUS_UNTREATED:Number = 0;
const STATUS_PROCESSING:Number = 1;

@Component({ components: { UlContentHeader, UlBreadcrumbs, UIDataTable, downloadFileInfo } })
export default class download extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ユーザー情報ダウンロード";
  headingSub = "Download customer information";
  breadCrumbs = [
    { text: "カスタマー", disabled: true },
    { text: "ユーザー情報ダウンロード", disabled: true }
  ];
  checkBoxList = [
    {'アプリ利用状況':1,'プロフィール情報':2,'スタンプカード':3},
    { 'ポイントカード(減算方式)': 4, 'ポイントカード(コンプリート)': 5, 'トーク利用状況': 7},
    {'次回予約情報': 8 ,'クーポン利用状況':10}
  ];
  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  // テーブルヘッダ
  tableHeaders = [
    { text: "", value: "yearMonth", sortable: false },
    {
      label: "",
      text: "",
      value: "custom",
      sortable: false
    }
  ];
  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    shopId: null as number | null,
    selectedFileTypes: []
  };

  inputParams = { id: 1 };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;
  dlPath = null as GetItem | null;

  // ------------

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
      return UserExportGet.getItems;
  }


  get shopItems() {
    return UserShopGet.getItems;
  }

  /**
   * ファイル形式
   */
  get formats() {
    return [
      { label: "CSV形式", value: Format.CSV }
    ];
  }

  get showCreateExcelStatus() {
    if (!this.inputOptions.shopId || this.createExcelStatus == 5) {
      return false;
    }
    return true;
  }

  get createExcelStatus() {
    if (!CreateExcel.isSuccess) {
      return 5;
    }

    return CreateExcel.getStatus;
  }

  get createExcelShopName() {
    if (!CreateExcel.isSuccess || !CreateExcel.getShopName) {
      return null;
    }
    return CreateExcel.getShopName;
  }

  get createExcelStatusString() {
    let result = '現在作成可能';
    switch (CreateExcel.getStatus) {
      case 0:
        result = '作成処理実行待ち';
        break;
      case 1:
        result = '現在作成処理中';
        break;
      case 3:
        result = '作成処理エラー';
        break;
      case 4:
        result = '一部帳票の作成エラー';
        break;
    }

    return result;
  }
  /**
   * 作成登録実行ボタンの活性化判定(未選択or登録済みはNG)
   * @returns boolean
   */
  checkRegistCreationButtonEnabled(createExcelStatus) {
    return !((!this.inputOptions.selectedFileTypes.length)
          || (createExcelStatus == STATUS_UNTREATED
          || createExcelStatus == STATUS_PROCESSING));
  }
  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await UserShopGet.getMainShop();
    if (!UserShopGet.isSuccess) {
      var errMessage = ''
      if (UserShopGet.getMessage == 'データが見つかりませんでした。') {
        errMessage = '権限が不足しています。\n本機能は本店の権限を保有しているアカウントのみ利用可能です。';
      }else{
        errMessage = UserShopGet.getMessage;
      }
      await Flash.setErrorNow({
        message: errMessage,
        showReloadButton: true,
      } as ErrorAlert);
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await UserExportGet.clearResponse();
    await UserShopGet.clearResponse();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    this.inputOptions.shopId &&
      (this.inputParams.id = this.inputOptions.shopId | 0);
    await Flash.clear();
    await this.search();
  }



  /**
   * 検索処理
   */
  async search() {
    if (!this.inputOptions.shopId) {
      return;
    }
    this.isLoading = true;
    await UserExportGet.get({ id: this.inputOptions.shopId } as GetRequest);
    if (!UserExportGet.isSuccess) {
      await Flash.setErrorNow({
        message: UserExportGet.getMessage,
        showReloadButton: true
      });
    }

    await CreateExcel.get({shop_id: this.inputOptions.shopId} as CreateExcelRequest);
    if (!CreateExcel.isSuccess) {
      await Flash.setErrorNow({
        message: CreateExcel.getMessage,
        showReloadButton: true
      });
    }

    this.isLoading = false;
  }


  goToIndex() {
    this.$router.push({ name: "customers" }).then();
  }


  /**
   * お客様エクセル生成処理登録
   */
  private async registExcelBatch(): Promise<boolean> {
    await RegistCreateExcel.register({shop_id: this.inputOptions.shopId, export_file_type: this.inputOptions.selectedFileTypes} as CreateExcelRequest);
    await CreateExcel.get({shop_id: this.inputOptions.shopId} as CreateExcelRequest);
    if (!CreateExcel.isSuccess) {
      await Flash.setErrorNow({
        message: CreateExcel.getMessage,
        showReloadButton: true
      });
    } else {
      await Flash.setSuccessNow({
        message: "作成処理を登録しました。",
        consumePath: ""
      });
    }

    return RegistCreateExcel.isSuccess;
  }
}
