import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as CreateExcelAPI from "@/api/create-excel";
import { isSuccess } from "@/api/response";
import { RegisterRequest } from "@/api/create-excel/request";
import { RegisterResponse } from "@/api/create-excel/response";

const MODULE_NAME = "create-excel/register";

/**
 * お客様エクセル生成処理登録APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  registerResponse: RegisterResponse = {} as RegisterResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerResponse);
  }

  get getMessage() {
    return this.registerResponse.message;
  }

  @MutationAction
  async register(registerRequest: RegisterRequest) {
    const registerResponse = await CreateExcelAPI.regist(registerRequest);
    return {
      registerResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      registerResponse: {} as RegisterResponse
    };
  }
}

export default getModule(Modify);
