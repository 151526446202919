import service from "@/api/service";
import { GetRequest } from "@/api/user-export/request";
import { GetResponse } from "@/api/user-export/response";

/**
 * ユーザー情報ファイルダウンロード一覧APIをコールします。
 *
 * @param getRequest ユーザー情報ファイルダウンロードのリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-user-export", getRequest);
  return response.data as GetResponse;
}
